html,
body,
main {
  background-color: #f9f9f9;
  margin: 0;
}

.selectedListItem::before {
  top: 0;
  left: 0;
  width: 4px;
  content: "";
  height: 48px;
  position: absolute;
  transition: all 0.3s ease-in-out;
  background-color: white;
  border-radius: 0 20px 20px 0;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.half__title {
  width: 100%;
  margin-bottom: 15px;
}

.half__title h4 {
  margin: 0;
  font-weight: 700;
  line-height: 24px;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  color: #003669;
}
.half__title span {
  display: block;
  font-weight: 400;
  line-height: 21px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #778ca2;
}

